import { onLoad } from './libs/helpers';
import { Popup } from './libs/popup';
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signOut,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { getFirebaseAuth } from './libs/firebase-auth';

const authPopupId = 'authPopup';
const defaultPhotoURL =
  'https://d1nhio0ox7pgb.cloudfront.net/_img/o_collection_png/green_dark_grey/256x256/plain/user.png';
let authPopup: Popup, authPopupValidationEl, authPopupEmailInputEl, authPopupPasswordInputEl;

onLoad(() => {
  createAuthPopup();
  onAuthStateChanged(getFirebaseAuth(), user => {
    updateSignInButton(user);
    if (user) {
      authPopup.close();
    } else {
      if (location.href.includes('openAuth')) {
        // to open a popup if URL contains "?openAuth=1". Used in email template.
        authPopup.open();
      }
    }
  });
});

function updateSignInButton(user) {
  const signInButtonElements = document.querySelectorAll('.sign-in-button');
  signInButtonElements.forEach(signInButtonElement => {
    if (user) {
      signInButtonElement.innerHTML = `<img src="${
        user.photoURL || defaultPhotoURL
      }" style="width: 30px; border-radius: 50%;"> ${user.displayName || user.email || user.phoneNumber} / Sign out`;
    } else {
      signInButtonElement.innerHTML = 'Sign in/up';
    }
  });
}

function createAuthPopup() {
  const html = `
    <div class="auth-popup-wrapper">
      <h4 style="margin: 0 0 0.8rem;">Sign in or Sign up using options below</h4>
      <div>
        <button type="button" onClick="signWithProvider('google')" class="w3-btn btn-google">
          <i class="fa fa-google" aria-hidden="true"></i> Google
        </button>
        <button type="button" onClick="signWithProvider('facebook')" class="w3-btn btn-facebook">
          <i class="fa fa-facebook" aria-hidden="true"></i> Facebook
        </button>
      </div>
      <p style="text-align: center">OR</p>
      <input type="email" id="emailAuthInput" placeholder="Email" class="w3-input" style="margin-bottom: 0.5rem"/>
      <input type="password" id="passwordAuthInput" placeholder="Password" class="w3-input" style="margin-bottom: 1rem"/>
      <div>
        <button type="button" onClick="signWithEmail('up')" class="w3-btn htpc-button">I'm new user</button>
        <button type="button" onClick="signWithEmail('in')" class="w3-btn htpc-button">Sign in</button>
      </div>
      <p class="validation" style="display: none;">
        <i class="fa fa-exclamation-triangle"></i>
        <span></span>
      </p>
    </div>`;
  authPopup = new Popup(authPopupId, html);
  authPopupValidationEl = authPopup.element.querySelector('.validation');
  authPopupEmailInputEl = authPopup.element.querySelector('#emailAuthInput');
  authPopupPasswordInputEl = authPopup.element.querySelector('#passwordAuthInput');
  authPopupPasswordInputEl.addEventListener('keyup', e => {
    if (e.keyCode === 13) {
      // keyCode of Enter is 13
      window['signWithEmail']('in');
    }
  });
}

window['signWithProvider'] = provider => {
  const providers = {
    google: new GoogleAuthProvider(),
    facebook: new FacebookAuthProvider(),
  };
  handleAuthPromise(signInWithPopup(getFirebaseAuth(), providers[provider], browserPopupRedirectResolver));
};

window['signWithEmail'] = stage => {
  const email = authPopupEmailInputEl.value.trim();
  const password = authPopupPasswordInputEl.value;
  const promise =
    stage === 'in'
      ? signInWithEmailAndPassword(getFirebaseAuth(), email, password)
      : createUserWithEmailAndPassword(getFirebaseAuth(), email, password);
  handleAuthPromise(promise);
};

async function handleAuthPromise(promise) {
  try {
    await promise;
    authPopupValidationEl.style.display = 'none';
  } catch (error) {
    let errorMessage = error.message;
    switch (error.code) {
      case 'auth/account-exists-with-different-credential': {
        if (error.email) {
          const methods = await fetchSignInMethodsForEmail(getFirebaseAuth(), error.email);
          errorMessage = `You used ${methods.join(', ')} with this email. Please proceed with it.`;
        } else {
          errorMessage = 'You signed in with different method before.';
        }
        break;
      }
      case 'auth/invalid-email': {
        errorMessage = 'Email is not valid';
        break;
      }
      case 'auth/user-not-found': {
        errorMessage = 'No user found with this email.';
        break;
      }
      case 'auth/wrong-password': {
        errorMessage = 'Wrong password or user has no password. Try Google/Facebook.';
        break;
      }
      case 'auth/weak-password': {
        errorMessage = 'Please use at least 6 symbols-length password.';
        break;
      }
      case 'auth/email-already-in-use': {
        errorMessage = 'Someone else uses this email. Try Google/Facebook.';
        break;
      }
    }
    authPopupValidationEl.style.display = 'block';
    authPopupValidationEl.querySelector('span').innerText = errorMessage;
    console.error(error.message);
    console.error(error.code);
  }
}

window['authToggle'] = () => {
  if (getFirebaseAuth().currentUser) {
    signOut(getFirebaseAuth());
    return;
  }
  authPopup.open();
};
